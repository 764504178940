
*, body {
    box-sizing: border-box;
}

body, html {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    font-family: Courier, monospace;
    font-display: fallback;
    font-size: 15px;
    font-weight: 400;
    color: #111111;
    margin: 0;
}

.bg {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
}

.body {
    text-align: center;
}

.intro {
    min-width: 100%;
    min-height: 100%;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.60);
}

.title {
    .logo {
        height: 206px;

        img {
            height: 100%;
            margin-right: 7px;
        }
    }

    h1 {
        font-family: Kanit;
        font-size: 100px;
        letter-spacing: 7px;
        margin: 0;
        padding: 0;
        text-shadow: 0 0 21px #444444;
        text-transform: uppercase;
        line-height: 1.3em;
        font-weight: bold;

        .flip {
            margin-top: 6px;
            vertical-align: top;
            margin-left: -15px;
        }
    }

    h2 {
        font-family: Kanit, Arial, sans-serif;
        font-size: 16px;
        letter-spacing: 10px;
        margin: 0;
        text-shadow: 0 0 21px #444444;
        text-transform: uppercase;
        color: #808080;
        max-width: 100%;
        text-align: center;
        padding: 0 20px;
        font-weight: bold;

    }
}

.services {
    min-width: 100%;
    // min-height: 100%;
    padding: 200px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background: #0B6884;
    background-color: rgba(11, 104, 132, .99);
    color: #ffffff;

    .inner {
        max-width: 700px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @media (max-width: 700px) {
            flex-direction: column;
        }
    }

    .question {
        font-family: Kanit;
        white-space: nowrap;
        font-size: 35px;
        padding: 20px;
        border-right: 1px solid #fff;
        margin-right: 20px;

        @media (max-width: 700px) {
            border-right: none;
            margin-right: unset;
            margin-bottom: 30px;
        }
    }

    .answer {
        font-size: 16px;
        line-height: 27px;
        @media (max-width: 700px) {
            text-align: center;
        }
    }
}

.keywords {
    min-width: 100%;
    padding: 100px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #0B6884;
}

.contact {
    min-width: 100%;
    // min-height: 100%;
    height:750px;
    padding: 100px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FCFCFF;

    .body {
        padding: 30px;
        max-width: 600px;
        width: 100%;
    }

    .contact-confirmation {
        display: none;
    }

    h3 {
        font-family: Kanit;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 7px;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
    }

    .error-message {
        display: none;
    }

    #send {
        color: #fff;
        cursor: pointer;
        padding: 20px 50px;
        display: block;
        background-color: #0B6884;
        border: #333;
        border-radius: 4px;
        font-size: 16px;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin: auto;
    }
}

.error {
    border-color: #000 !important;
}

.grecaptcha-badge {
    display: none !important;
}

.flip {
    display: inline-block;
    transform: scaleY(-1);
}



@media (max-width: 700px) {
    .title {
        .logo {
            height: 140px;
        }

        h1 {
            font-size: 60px;
            letter-spacing: 6px;
            .flip {
                margin-top: 3px;
                margin-left: -10px;
            }
        }

        h2 {
            font-size: 15px;
            letter-spacing: 7px;
        }
    }
}

@media (max-width: 320px) {
    .title {
        .logo {
            font-size: 150px;
            height: 150px;
            line-height: 150px;
        }

        h1 {
            font-size: 30px;
            letter-spacing: 6px;
            .flip {
                margin-top: 2px;
                margin-left: -5px;
            }
        }

        h2 {
            font-size: 13px;
            letter-spacing: 7px;
        }
    }

    .contact .body {
        padding: 30px;
        max-width: 320px;
    }
}
