textarea, input {
    font-family: Courier, monospace;
    display: block;
    margin: 0 auto 20px auto;
    width: 100%;
    min-height: 45px;
    outline: 0;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255, 255, 255, 0);
    line-height: 1.21428571em;
    padding: .67857143em 1em;
    font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    color: rgba(0, 0, 0, .87);
    -webkit-transition: color .1s ease, border-color .1s ease;
    transition: color .1s ease, border-color .1s ease;
}

.button {
    color: #fff;
    cursor: pointer;
    padding: 20px 50px;
    display: block;
    background-color: #0B6884;
    border: #333;
    border-radius: 4px;
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: auto;
}
